<template>
  <div ref="sidebarRef" class="asf-sidebar">
    <AsfOverlay :visible="isOverlayVisible" />
    <transition :name="transition">
      <aside
        v-if="visible"
        ref="content"
        v-focus-trap="useFocusTrap"
        v-click-outside="closeEventHandler"
        v-e2e="`sidebar-${position}`"
        class="asf-sidebar__aside"
        :class="[`is-${position}`]"
        role="complementary"
        @keydown.esc="closeEventHandler"
      >
        <AsfSidebarHead
          :title="barTitle || title"
          :is-back-button="showBackButton || isBackButton"
          :is-close-button="isCloseButton"
          @click:back="backEventHandler"
          @click:close="closeEventHandler"
        >
          <slot name="content-bar" :title="barTitle || title" :close-event-handler="closeEventHandler" />
        </AsfSidebarHead>
        <div class="asf-sidebar__content is-scrollable" v-e2e="'content-sliding-panel'">
          <slot :handle-level-change="handleLevelChange" :close-event-handler="closeEventHandler" />
        </div>
        <div v-if="$slots['content-bottom-section']" class="asf-sidebar__bottom-section">
          <!--@slot Use this slot to place additional content under the scrollable section. -->
          <slot name="content-bottom-section" />
        </div>
      </aside>
    </transition>
  </div>
</template>
<script lang="ts">
import { AsfSidebarProps, AsfSidebarSlidingPanelState, AsfTransitions } from '@ui/types'

export default defineComponent({
  name: 'AsfSidebar',
  props: {
    /**
     * Sidebar position
     * @values `left` and `right`
     */
    position: { type: String as PropType<AsfSidebarProps['position']>, default: 'left' },
    /** Top bar title */
    title: { type: String as PropType<AsfSidebarProps['title']>, default: '' },
    /** Defines to show or hide the sidebar itself */
    visible: { type: Boolean as PropType<AsfSidebarProps['visible']>, required: true },
    /** Defines to show or hide the overlay */
    overlay: { type: Boolean as PropType<AsfSidebarProps['overlay']>, default: true },
    /** Defines to show or hide the "back" button */
    isBackButton: { type: Boolean as PropType<AsfSidebarProps['isBackButton']>, default: false },
    /** Defines to show or hide the close button */
    isCloseButton: { type: Boolean as PropType<AsfSidebarProps['isCloseButton']>, default: true },
    /** Defines to use init Focus Trap directive */
    useFocusTrap: { type: Boolean as PropType<AsfSidebarProps['useFocusTrap']>, default: true }
  },
  setup(props: AsfSidebarProps, { emit }) {
    const instance = useCurrentInstance()
    const barTitle: Ref<AsfSidebarProps['title']> = ref('')
    const showBackButton = ref(false)
    const sidebarRef: Ref<HTMLElement | null> = ref(null)

    const backEventHandler = () => EventBus.emit('sidebar:back')
    const closeEventHandler = () => {
      barTitle.value = ''
      showBackButton.value = false
      emit('sidebar:close')
    }

    const isOverlayVisible = computed(() => props.visible && props.overlay)
    const transition = computed<AsfTransitions>(() => {
      if (props.position === 'left') {
        return 'asf-slide-left'
      }

      return 'asf-slide-right'
    })

    const handleLevelChange = (payload: AsfSidebarSlidingPanelState) => {
      barTitle.value = payload.panels[payload.level - 1]?.label || props.title
      showBackButton.value = Boolean(payload.level)

      useBodyScrollLock(props, instance, closeEventHandler, true)
    }

    useBodyScrollLock(props, instance, closeEventHandler)

    return {
      barTitle,
      sidebarRef,
      transition,
      showBackButton,
      isOverlayVisible,
      backEventHandler,
      closeEventHandler,
      handleLevelChange
    }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Sidebar/Sidebar.css';
</style>
